import type { PricePublic, RetreatPublic } from "~/schemas/retreat";

export const RetreatPrices = ({ retreat }: { retreat: RetreatPublic }) => {
  return (
    <>
      <h3>Prices</h3>
      <ul>
        {retreat.prices.map((price) => (
          <Price price={price} key={price.id} />
        ))}
      </ul>
    </>
  );
};
const Price = ({ price }: { price: PricePublic }) => {
  const priceUsd = (price.cents / 100).toFixed(2);

  return (
    <li key={price.id}>
      {price.name}: ${priceUsd}
    </li>
  );
};
